import { Link, routes, useMatch, useParams } from '@redwoodjs/router'
import { Toaster } from '@redwoodjs/web/toast'

type LayoutProps = {
  title: string
  titleTo: string
  titleToParams?: string[]
  buttonLabel: string
  buttonTo: string
  buttonToParams?: string[]
  children: React.ReactNode
}

const ScaffoldLayout = ({
  title,
  titleTo,
  titleToParams,
  buttonLabel,
  buttonTo,
  buttonToParams,
  children,
}: LayoutProps) => {
  // Enable reacting to whether we're on the 'create new' page of this entity
  const pageParams = useParams()
  const buttonToPageParams = buttonToParams
    ? buttonToParams.reduce((acc: Record<string, string>, param: string) => {
        acc[param] = pageParams[param]
        return acc
      }, {})
    : {}
  const titleToPageParams = titleToParams
    ? titleToParams.reduce((acc: Record<string, string>, param: string) => {
        acc[param] = pageParams[param]
        return acc
      }, {})
    : {}

  const isOnNewItemPage = useMatch(
    buttonTo ? routes[buttonTo](buttonToPageParams) : ''
  ).match

  return (
    <div className="rw-scaffold">
      <Toaster toastOptions={{ className: 'rw-toast', duration: 6000 }} />
      <header className="rw-header">
        {titleTo && title ? (
          <h1 className="rw-heading rw-heading-primary">
            <Link to={routes[titleTo](titleToPageParams)} className="rw-link">
              {title}
            </Link>
          </h1>
        ) : null}
        {buttonLabel && buttonTo && !isOnNewItemPage ? (
          <Link
            to={routes[buttonTo](buttonToPageParams)}
            className="rw-button rw-button-green"
          >
            <div className="rw-button-icon">+</div> {buttonLabel}
          </Link>
        ) : null}
      </header>
      <main className="rw-main">{children}</main>
    </div>
  )
}

export default ScaffoldLayout
