/* eslint-disable react/no-unescaped-entities */
import React, { Component } from 'react'

import * as Sentry from '@sentry/browser'
import { DateTime } from 'luxon'
import {
  Button,
  Container,
  Dropdown,
  Header,
  Icon,
  Image,
  List,
  Menu,
  Segment,
  Sidebar,
  Visibility,
} from 'semantic-ui-react'

import { Link, navigate, NavLink, routes, useLocation } from '@redwoodjs/router'
import { Head } from '@redwoodjs/web'
import { Toaster } from '@redwoodjs/web/toast'

import { useAuth } from 'src/auth'
import NotificationsCell from 'src/components/Notification/NotificationsCell'
import { getProfileThumbnailUrl } from 'src/util/getPhotoThumbnailUrl'
import { Media, MediaContextProvider } from 'src/util/media'

const TopRightMenu = ({ mobile }) => {
  const { userMetadata, currentUser, logOut, profile } = useAuth()

  const fullName = currentUser?.profile?.fullName || userMetadata?.full_name
  const profileId = currentUser?.profileId

  const MenuTrigger = (
    <span className={profile?.photo?.url ? '' : ' mb-3'}>
      {profile?.photo?.url ? (
        <span className=" mr-2">
          <Image src={getProfileThumbnailUrl({ profile, height: 30 })} avatar />
        </span>
      ) : null}
      <span>{mobile ? '' : fullName || currentUser.email}</span>
    </span>
  )

  return (
    <>
      <Dropdown
        item
        direction="left"
        className={`item align-middle -mb-3 ${mobile ? ' pb-1' : ''}`}
        icon={mobile ? 'setting' : null}
        trigger={MenuTrigger}
      >
        <Dropdown.Menu>
          <Dropdown.Item
            icon="user"
            text="Profile"
            as={NavLink}
            to={routes.profile({ id: profileId || '' })}
          />
          <Dropdown.Item
            icon="setting"
            text="Settings"
            as={NavLink}
            to={routes.settings()}
          />
          <Dropdown.Item
            icon="mail"
            text="Subscriptions"
            as={NavLink}
            to={routes.searchSavedSearches()}
          />
          <Dropdown.Item
            onClick={() => {
              logOut()
            }}
            text="Log Out"
            icon="log out"
          />
        </Dropdown.Menu>
      </Dropdown>

      <NotificationsCell />
    </>
  )
}

const NavigationMenu = ({ includeAuth, signUp, logIn }) => {
  const { logOut, isAuthenticated, hasRole, currentUser } = useAuth()
  const activeClassName = 'active item'
  const mapSearchLink = routes.mapSearch()

  return (
    <React.Fragment>
      {isAuthenticated && (
        <NavLink
          to={routes.feed()}
          className="item"
          activeClassName={activeClassName}
        >
          Home
        </NavLink>
      )}
      {isAuthenticated && (
        <Dropdown item className="item" text="Search" direction="left" as="div">
          <Dropdown.Menu as="div">
            <Dropdown.Item
              as={NavLink}
              to={mapSearchLink}
              icon="map pin"
              text="Map Search"
              activeClassName={activeClassName}
            ></Dropdown.Item>
            <Dropdown.Item
              icon="search"
              text="Saved Searches"
              as={NavLink}
              to={routes.searchSavedSearches()}
              activeClassName={activeClassName}
            ></Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      )}
      {isAuthenticated && (
        <Dropdown
          item
          className="item"
          text="Listings"
          direction="left"
          as="div"
        >
          <Dropdown.Menu as="div">
            <Dropdown.Item
              as={NavLink}
              to={routes.listings({
                profileId: currentUser?.profileId || '',
              })}
              text="My Listings"
              icon="home"
              activeClassName={activeClassName}
            />
            {(hasRole('admin') || hasRole('brokerage') || hasRole('agent')) && (
              <Dropdown.Item
                as={NavLink}
                to={routes.buyerMatchesForProfile({
                  profileId: currentUser?.profileId || '',
                })}
                text="Buyer Matches"
                icon="find"
                activeClassName={activeClassName}
              />
            )}
          </Dropdown.Menu>
        </Dropdown>
      )}
      {isAuthenticated && (hasRole('admin') || hasRole('agent')) && (
        <NavLink
          to={routes.clients()}
          className="item"
          activeClassName={activeClassName}
        >
          My Clients
        </NavLink>
      )}
      {(hasRole('admin') || hasRole('brokerage')) && (
        <NavLink
          to={routes.brokerages()}
          className="item"
          activeClassName={activeClassName}
        >
          Brokerage
        </NavLink>
      )}
      {(hasRole('admin') || hasRole('brokerage')) && (
        <NavLink
          to={routes.reports()}
          className="item"
          activeClassName={activeClassName}
        >
          Reports
        </NavLink>
      )}
      {hasRole('admin') && (
        <NavLink
          to={routes.multipleListingServices()}
          className="item"
          activeClassName={activeClassName}
        >
          MLS
        </NavLink>
      )}
      {hasRole('admin') && (
        <NavLink
          to={routes.searchProfiles()}
          className="item"
          activeClassName={activeClassName}
        >
          Profiles
        </NavLink>
      )}
      {includeAuth && !isAuthenticated && (
        <Menu.Item onClick={logIn}>Log in</Menu.Item>
      )}
      {includeAuth && !isAuthenticated && (
        <Menu.Item onClick={signUp}>Sign Up</Menu.Item>
      )}
      {includeAuth && isAuthenticated && (
        <Menu.Item
          onClick={() => {
            logOut()
          }}
          as={'div'}
        >
          Log Out
        </Menu.Item>
      )}
    </React.Fragment>
  )
}

/* Heads up!
 * Neither Semantic UI nor Semantic UI React offer a responsive navbar, however, it can be implemented easily.
 * It can be more complicated, but you can create really flexible markup.
 */
class DesktopContainer extends Component {
  state = {}

  hideFixedMenu = () => this.setState({ fixed: false })
  showFixedMenu = () => this.setState({ fixed: true })

  render() {
    const {
      children,
      signUp,
      logIn,
      isAuthenticated,
      Hero,
      heroSegmentClassName,
      isLandingPage,
      isSignupPage,
      isLoginPage,
      profile,
    } = this.props
    const { fixed } = this.state
    const showBlackLetterLogo = isLandingPage || fixed
    const firstBrokerage = profile?.brokerages?.[0]
    const navLogoDarkBg =
      firstBrokerage?.topNavLogoDarkBackgroundPath ||
      '/brokerages/privatecollection/PrivateCollection_topleft.png'
    const navLogoLightBg =
      firstBrokerage?.topNavLogoLightBackgroundPath ||
      '/brokerages/privatecollection/PrivateCollection_topleft.png'

    return (
      <Media
        greaterThan="mobile"
        className={
          process.env.NODE_ENV === 'development' ? 'debug-screens' : ''
        }
      >
        <Visibility
          once={false}
          onBottomPassed={this.showFixedMenu}
          onBottomPassedReverse={this.hideFixedMenu}
        >
          <Segment
            inverted
            textAlign="center"
            style={{
              //minHeight: Hero ? 520 : 0,
              padding: Hero ? '0em 0em 0em' : '0em 0em .3em',
            }}
            vertical
            className={heroSegmentClassName}
          >
            <Menu
              fixed={fixed ? 'top' : null}
              inverted={!fixed}
              pointing={!fixed}
              secondary={!fixed}
              size="large"
              style={{ zIndex: '999' }}
              className="mb-0"
            >
              <Container fluid>
                <span
                  className="self-center text-3xl font-semibold text-white inline-block mx-4"
                  style={{
                    alignSelf: 'center',
                    marginTop: '3px',
                    color: fixed ? 'black' : 'white',
                  }}
                >
                  {showBlackLetterLogo && (
                    <Image
                      src={navLogoLightBg}
                      size="small"
                      as={Link}
                      to={isAuthenticated ? routes.feed() : routes.landing()}
                    />
                  )}
                  {!showBlackLetterLogo && (
                    <Image
                      src={navLogoDarkBg}
                      size="small"
                      as={Link}
                      to={isAuthenticated ? routes.feed() : routes.landing()}
                    />
                  )}
                </span>
                {!isLoginPage ? (
                  <NavigationMenu signUp={signUp} logIn={logIn} />
                ) : null}
                {!isAuthenticated && (
                  <Menu.Item position="right">
                    <Button secondary onClick={logIn}>
                      Log in
                    </Button>
                    {!isSignupPage && (
                      <Button
                        primary
                        style={{ marginLeft: '0.5em' }}
                        onClick={signUp}
                      >
                        Sign Up
                      </Button>
                    )}
                  </Menu.Item>
                )}
                {isAuthenticated && (
                  <Menu.Item position="right" className=" py-0">
                    <TopRightMenu fixed={fixed} />
                  </Menu.Item>
                )}
              </Container>
            </Menu>
            {Hero && <Hero signUp={signUp} />}
          </Segment>
        </Visibility>

        {children}
      </Media>
    )
  }
}

class MobileContainer extends Component {
  state = {}

  handleSidebarHide = () => this.setState({ sidebarOpened: false })

  handleToggle = () => this.setState({ sidebarOpened: true })

  render() {
    const {
      children,
      signUp,
      logIn,
      isAuthenticated,
      Hero,
      heroSegmentClassName,
      profile,
    } = this.props
    const { sidebarOpened } = this.state
    const firstBrokerage = profile?.brokerages?.[0]
    const navLogoDarkBg =
      firstBrokerage?.topNavLogoDarkBackgroundPath ||
      '/brokerages/privatecollection/PrivateCollection_topleft.png'

    return (
      <Media as={Sidebar.Pushable} at="mobile">
        <Sidebar.Pushable className=" min-h-[calc(100vh-8rem)]">
          <Sidebar
            as={Menu}
            animation="overlay"
            inverted
            onHide={this.handleSidebarHide}
            vertical
            visible={sidebarOpened}
          >
            <NavigationMenu includeAuth />
          </Sidebar>

          <Sidebar.Pusher dimmed={sidebarOpened}>
            <Segment
              inverted
              textAlign="center"
              style={{
                //minHeight: Hero ? 350 : 0,
                padding: Hero ? '0em 0em' : '0em 0em .3em',
              }}
              vertical
              className={heroSegmentClassName}
            >
              <Container>
                <Menu pointing secondary size="large">
                  <Menu.Item
                    onClick={this.handleToggle}
                    className="p-0"
                    as="div"
                  >
                    <Icon name="sidebar" size="large" inverted />
                    <Image
                      src={navLogoDarkBg}
                      size="small"
                      as={Link}
                      to={routes.feed()}
                    />
                  </Menu.Item>
                  {!isAuthenticated && (
                    <Menu.Item position="right">
                      <Button as="a" secondary onClick={logIn}>
                        Log in
                      </Button>
                      <Button
                        as="a"
                        primary
                        style={{ marginLeft: '0.5em' }}
                        onClick={signUp}
                      >
                        Sign Up
                      </Button>
                    </Menu.Item>
                  )}
                  {isAuthenticated && (
                    <Menu.Item position="right" className="pb-2 pt-0 pr-1">
                      <TopRightMenu mobile />
                    </Menu.Item>
                  )}
                </Menu>
              </Container>
              {Hero && <Hero mobile signUp={signUp} />}
            </Segment>

            {children}
          </Sidebar.Pusher>
        </Sidebar.Pushable>
      </Media>
    )
  }
}

const ResponsiveContainer = ({
  children,
  signUp,
  logIn,
  logOut,
  isAuthenticated,
  Hero,
  heroSegmentClassName,
  isLandingPage,
  isSignupPage,
  isLoginPage,
  profile,
}) => (
  /* Heads up!
   * For large applications it may not be best option to put all page into these containers at
   * they will be rendered twice for SSR.
   */
  <MediaContextProvider>
    <DesktopContainer
      signUp={signUp}
      logIn={logIn}
      logOut={logOut}
      Hero={Hero}
      isAuthenticated={isAuthenticated}
      heroSegmentClassName={heroSegmentClassName}
      isLandingPage={isLandingPage}
      isSignupPage={isSignupPage}
      isLoginPage={isLoginPage}
      profile={profile}
    >
      {children}
    </DesktopContainer>
    <MobileContainer
      signUp={signUp}
      logIn={logIn}
      logOut={logOut}
      isAuthenticated={isAuthenticated}
      Hero={Hero}
      heroSegmentClassName={heroSegmentClassName}
      isSignupPage={isSignupPage}
      isLoginPage={isLoginPage}
      profile={profile}
    >
      {children}
    </MobileContainer>
  </MediaContextProvider>
)

const Footer = () => {
  const reportError = () => {
    Sentry.withScope((scope) => {
      scope.setTag('mechanism', 'Footer')
      scope.setLevel('error')

      const eventId = Sentry.captureException(
        new Error('User initiated error report')
      )
      Sentry.showReportDialog({ eventId })
    })
  }
  return (
    <Segment inverted vertical className="px-8 py-2">
      <footer className="flex gap-8 flex-wrap">
        <div>
          <Header inverted as="h4" content="Company" />
          <List link inverted>
            <NavLink className="item" to={routes.about()}>
              About
            </NavLink>
            <NavLink className="item" to={routes.contact()}>
              Contact Us
            </NavLink>
          </List>
        </div>
        <div>
          <List link inverted className=" m-9">
            <NavLink className="item" to={routes.privacyPolicy()}>
              Privacy Policy
            </NavLink>
            <a href="#" className="item" onClick={reportError}>
              Report a problem
            </a>
          </List>
        </div>
        <div>
          <Header as="h4" inverted>
            Private Collection
          </Header>
          <p>
            The best journey takes you home.
            <br />
            Copyright {DateTime.now().year} Private Collection.
          </p>
        </div>
      </footer>
    </Segment>
  )
}

const HeroLayout = ({
  children,
  Hero = null,
  title,
  heroSegmentClassName = null,
}) => {
  const { pathname } = useLocation()

  const isLandingPage = pathname === routes.landing()
  const isSignupPage = pathname === routes.signUp()
  const isLoginPage = pathname === routes.logIn()
  const { logOut, isAuthenticated, currentUser } = useAuth()

  return (
    <div className="flex flex-col h-screen">
      <main className="flex-grow">
        <ResponsiveContainer
          signUp={() => navigate(routes.signUp({ view: 'sign_up' }))}
          logIn={() => navigate(routes.logIn())}
          logOut={logOut}
          isAuthenticated={isAuthenticated}
          Hero={Hero}
          heroSegmentClassName={heroSegmentClassName}
          isLandingPage={isLandingPage}
          isSignupPage={isSignupPage}
          isLoginPage={isLoginPage}
          profile={currentUser?.profile}
        >
          <div>
            {title && (
              <Head>
                <title>{title}</title>
              </Head>
            )}
            <Toaster />
            {children}
          </div>
        </ResponsiveContainer>
      </main>
      <Footer />
    </div>
  )
}

export default HeroLayout
